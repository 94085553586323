import Image from 'next/image'
import Link from 'next/link'

import { Separator } from '@wartek-id/separator'
import { Text } from '@wartek-id/text'

import { DesktopNavbar } from 'components/DesktopNavbar'

interface ReasonItemProps {
  number: number | string
  description: string
  actionText: string
  actionLink?: string
}

const ReasonItem = ({
  number,
  description,
  actionText,
  actionLink,
}: ReasonItemProps) => {
  return (
    <div className="flex items-start gap-3">
      <div className="relative flex items-center justify-center rounded-full border-2 border-subdued p-3">
        <Text variant="body-sm-bold" className="absolute">
          {number}
        </Text>
      </div>

      <div>
        <Text>{description}</Text>
        <Link href={actionLink}>
          <Text
            variant="action"
            color="primary"
            className="mt-4 cursor-pointer"
          >
            {actionText}
          </Text>
        </Link>
      </div>
    </div>
  )
}

const ForbiddenPage = () => {
  return (
    <div className="w-full bg-white">
      <DesktopNavbar />
      <div className="flex justify-center py-20">
        <div className="max-w-[600px]">
          <Image
            src="/images/img-no-access.png"
            width={110}
            height={110}
            alt="No access"
          />
          <Text variant="display-sm" className="mt-9">
            Anda tidak memiliki akses
          </Text>
          <Text color="subdued" className="mt-3">
            Hal ini mungkin terjadi karena:
          </Text>

          <div className="mt-8 flex flex-col gap-4">
            <ReasonItem
              number="1"
              description="Anda menggunakan akun email pribadi. Pilih akun Google yang berakhiran @dinas.belajar.id atau @admin.belajar.id."
              actionText="Masuk kembali"
              actionLink="/logout"
            />

            <Separator />

            <ReasonItem
              number="2"
              description="Akun belajar.id Anda belum dibuat. Anda dapat mengajukan pembuatan akun ke layanan bantuan (helpdesk) dengan mengisi formulir berikut."
              actionText="Isi formulir pengajuan"
              actionLink="https://pusatinformasi.pengangkatan-ksps.kemdikbud.go.id/hc/en-gb"
            />

            <Separator />

            <ReasonItem
              number="3"
              description="Akun belajar.id Anda belum diaktifkan. Anda dapat melihat cara mengaktifkan akun dengan mengunjungi situs web belajar.id berikut."
              actionText="Kunjungi situs web belajar.id"
              actionLink="https://belajar.id/"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

ForbiddenPage.layoutOpts = {
  classNameProp: '!bg-white flex justify-center !p-0',
}

export default ForbiddenPage
