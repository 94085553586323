import { useTracker } from 'api/tracker/useAPITracker'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter, useSearchParams } from 'next/navigation'
import { signIn } from 'next-auth/react'
import React from 'react'

import { Button } from '@wartek-id/button'
import { Card } from '@wartek-id/card'
import { Icon } from '@wartek-id/icon'
import { Text } from '@wartek-id/text'

import { useAuth } from 'utils/hooks/useAuth'
import { useRoles } from 'utils/hooks/useRoles'

import ForbiddenPage from './forbidden'

const Login = () => {
  const router = useRouter()
  const query = useSearchParams()
  const { bcksUserSession, userHasNoAccess } = useAuth()
  const { isGtkUser, isKemendagriUser } = useRoles()
  const { satuEventTrack } = useTracker()

  if (userHasNoAccess) {
    return <ForbiddenPage />
  }

  if (bcksUserSession) {
    satuEventTrack({ event_name: 'login' })

    if (query.get('callbackUrl')) {
      router.replace(query.get('callbackUrl'))
      return null
    }

    if (isGtkUser) {
      router.replace('/region')
    } else if (isKemendagriUser) {
      router.replace('/data_gp')
    } else {
      router.replace((query.get('from') as string) || '/home')
    }
    return null
  }

  return (
    <div className="flex place-content-center !h-full">
      <div className="absolute top-0 bg-[#E7F6FE] p-5 sm:invisible">
        <div className="flex items-center">
          <Icon color="informational">desktop_windows</Icon>
          <Text variant="body-sm-bold" className="ml-4">
            Gunakan komputer/laptop untuk menavigasi Ruang Kolaborasi dengan
            lebih mudah.
          </Text>
        </div>
      </div>
      <div className="flex h-full w-[500px] flex-col p-6">
        <div className="flex flex-grow flex-col place-content-center items-center">
          <Card className="px-4 pb-20 pt-16 sm:px-16">
            <div className="flex">
              <Image
                priority
                src="/images/logo-ruang-gtk.svg"
                alt="Merdeka Mengajar"
                width={112}
                height={60}
              />
            </div>

            <div className="mt-6 font-semibold">
              <Text variant="display-md" className="mt-2">
                Sistem Pengangkatan Kepala Sekolah dan Pengawas Sekolah
              </Text>
            </div>

            <div className="mt-9">
              <Button
                fullWidth
                className="flex rounded-lg !p-1"
                onClick={() => signIn('google')}
              >
                <div className="flex h-[38px] w-[38px] items-center justify-center rounded-sm bg-white">
                  <Image
                    src="/images/google-logo.png"
                    width={20}
                    height={20}
                    alt="Google logo"
                  />
                </div>
                <div className="flex-1 text-[15px] font-semibold leading-[22px]">
                  Masuk dengan google
                </div>
              </Button>
            </div>
          </Card>

          <div className="mt-6 w-full gap-4 sm:mt-9 flex justify-center">
            <Link href="https://ksps.zendesk.com/hc" passHref>
              <Text
                variant="body-sm-bold"
                color="subdued"
                className="text-center"
              >
                Bantuan
              </Text>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

Login.layoutOpts = {
  classNameProp: 'h-screen',
}

export default Login
